import React from "react";
import { Dialog } from 'primereact/dialog';

const ModalCancelBooking = ({
    open = false,
    onClose = () => console.log('onClose'),
    onCancel = () => console.log('No')
}) => {
    return (
        <Dialog visible={open} style={{ width: '500px' }} onHide={() => onClose()} draggable={false} resizable={false} closable={false}>
            <div class="bModal bCard" id="cancelMeetingModal" style={{ display:'block'}}>
                <div class="bInner">
                    <div class="bTitle">
                        <span>ยกเลิกการนัดหมาย</span>
                        <p>คุณแม่ต้องการยกเลิกการนัดหมายหรือไม่?</p>
                    </div>
                    <div class="bBtn">
                        <button class="btn outline btnCloseModal" onClick={onClose}>ทำการนัดหมายต่อ</button>
                        <button class="btn  btnCloseModal" onClick={onCancel}>ยกเลิกการนัดหมาย</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ModalCancelBooking
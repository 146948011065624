import React from 'react'
import { Dialog } from 'primereact/dialog';

const ModalNotEnoughPoint = ({
    open = false,
    onClose = () => console.log('onClose'),
    statuscode = "40501",
}) => {
    return (
        <Dialog visible={open} style={{ width: '500px' }} onHide={() => onClose()} draggable={false} resizable={false} closable={false}>
            <div className="bModal bCard" id="failModal" style={{ display: 'block' }}>
                <div className="bInner">
                    <div className="bTitle">
                        <span>ไม่สามารถทำการนัดหมายได้</span>
                        <p>
                            ขออภัย<br />คุณมีจำนวน Point ไม่เพียงพอในการรับสิทธิพิเศษนี้
                            <br />({statuscode})
                        </p>
                    </div>
                    <div className="bBtn">
                        <button className="btn btnCloseModal" onClick={onClose}>กลับสู่หน้าแรก</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ModalNotEnoughPoint
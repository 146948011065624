import React, { useEffect,useState } from 'react'
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { GetBZBuserProfile } from 'services/Third-party/GetBZBuserProfile';
import useBookingPage from 'services/store/BookingPage';
import { useSearchParams } from "react-router-dom";
import ModalPDPA from 'components/modal/ModalPDPA';
import useLoadedStore from 'services/store/LoadedPage';
import ModalNoMeetingLink from 'components/modal/ModalNoMeetingLink';
const RegisterPolicy = () => {
    const { isBooking,setIsBooking } = useLoadedStore();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { step0,setStep0,setProfile,setStep1,setStep2,setStep3 } = useBookingPage();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenNoLink, setIsOpenNoLink] = useState(false);
    const userToken = window.localStorage.getItem('userToken');
    const qToken =  searchParams.get('token') ?? userToken;
    window.localStorage.setItem('userToken',qToken);
    const handleOnYes = () =>{
        const payload = {
            isConsent: 1
        };
        setStep0(payload);
        setIsBooking(true);
        navigate('/mom-detail');
    }
    const handleOnNo = () =>{
        const payload = {
            isConsent: 0
        };
        setStep0(payload);
        setIsBooking(true);
        navigate('/mom-detail');
    }
    const handleNext = async () => {
        const payload = {
            isConsent: 1
        };
        setStep0(payload);
        setIsBooking(true);
        navigate('/mom-detail');
    };
    const handlePrev = () => {
        setIsOpen(true);
    };
    const handleLink = () => {
        window.location.href = 'https://www.google.com/';
    };

    const fetchAPI = async () => {
        const profile = await GetBZBuserProfile(qToken);
        console.log("🚀 ~ useEffect ~ profile:", profile) 
        if(profile.success){
           return setProfile({ ...profile?.data })
        }
        return alert("error");
    }
    const resetForm = async () => {
        const payload = {
            age: "",
            consult: "",
            note: "",
        }
        const payload2 = {
            doctor: 0
        }
        const payload3 =  {
            slot: "",
            consultDate:new Date(),
        }
        await setStep1(payload);
        await setStep2(payload2);
        await setStep3(payload3);
    }
    useEffect(() => {
        if(!isBooking){
            resetForm();
        }
        setProfile({})
        fetchAPI()
    }, [])
    return (
        <div>
            <Helmet>
                <title>S-Mom</title>
                <meta name="title" content="S-Mom" />
                <meta name="description" content="" />
                <meta name="keyword" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="" />
            </Helmet>
            <div id="pagePolicy">
                <main>
                    <header>
                        <div className="tHead">
                            <p>ข้อมูลความเป็นส่วนตัว</p>
                        </div>
                    </header>
                    <div className="wrapContent">
                        <section id="pPolicy">
                            <div className="bContent">
                                <p>ยินยอมข้อมูลความเป็นส่วนตัว</p>
                                <div className="container">
                                    <div className="tContent">
                                        <p>
                                            S-MomClub สวัสดีค่ะ การปรึกษาแพทย์และทีมพยาบาลออนไลน์
                                            เนสท์เล่จำเป็นต้องขออนุญาตเก็บข้อมูลการสนทนาและข้อมูลส่วนบุคคล
                                            ซึ่งรวมถึงข้อมูลที่มีความอ่อนไหว เช่น ข้อมูลสุขภาพของคุณ
                                            ลูกค้าตามกฎหมาย
                                        </p>
                                        <br />
                                        <p>
                                            สามารถดูประกาศความเป็นส่วนตัวของเนสท์เล่ ได้ที่<br />
                                            <a href="">https://www.s-momclub.com/privacy-policy </a>
                                        </p>
                                        <br />
                                        <p>
                                            หากยินยอมให้มีการเก็บข้อมูล กรุณากดปุ่ม "ยินยอม" หรือ
                                            "ไม่ยินยอม"เพื่อที่ทางบริษัทจะได้ดำเนินการต่อได้นะคะ
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section id="pActionBtn">
                        <div className="bBtn">
                            <button onClick={handlePrev} className="btn outline">ไม่ยินยอม</button>
                            <button onClick={handleNext} className="btn">ยินยอม</button>
                            {/* <!-- เมื่อเลือกประเภทแล้วให้เอา disabled ออก --> */}
                        </div>
                    </section>
                </main>
            </div>
            <ModalNoMeetingLink open={isOpenNoLink} onClose={handleLink}/>
            <ModalPDPA open={isOpen} onClose={() => setIsOpen(false)} onYes={handleOnYes} onNo={handleOnNo}/>
        </div>
    )
}

export default RegisterPolicy
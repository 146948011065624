import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  customer: null,
  currentPoint: 0,
  isLoaded: false,
};

export const CurrentPointSlice = createSlice({
  name: "CurrentPoint",
  initialState: defaultData,
  reducers: {
    setCurrentPoint: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
      // state.dataUser = action.payload;
    },
    resetCurrentPoint: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentPoint, resetCurrentPoint } = CurrentPointSlice.actions;

export default CurrentPointSlice.reducer;

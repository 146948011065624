import ModalPui from "components/modal/ModalBookingSuccess";
import BookingTime from "pages/register/BookingTime";
import Conclustion from "pages/register/Conclustion";
import DoctorSelect from "pages/register/DoctorSelect";
import HandlePage from "pages/register/HandlePage";
import MomDetail from "pages/register/MomDetail";
import RegisterPolicy from "pages/register/RegisterPolicy";
import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";



const appRouter = (props) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RegisterPolicy />} />
        <Route path="/mom-detail" element={<MomDetail/>} />
        <Route path="/doctor-select" element={<DoctorSelect/>} />
        <Route path="/booking-time" element={<BookingTime/>} />
        <Route path="/conclustion" element={<Conclustion/>} />
        <Route path="/register-policy" element={<RegisterPolicy/>} />
        <Route path="/modal" element={ <ModalPui/>} />
        <Route path="/handle-page" element={ <HandlePage/>} />
      </Routes>
    </Router>
  );
};

export default appRouter;
import React from "react";
import { Dialog } from 'primereact/dialog';

const ModalFailMeeting = ({
    open = false,
    onClose = () => console.log('onClose'),
    onFail = () => console.log('Fail')
}) => {
    return (
        <Dialog visible={open} style={{ width: '500px' }} onHide={() => onClose()} draggable={false} resizable={false} closable={false}>
            <div className ="bModal bCard" id="failMeetingModal" style={{ display: 'block' }}>
                <div className ="bInner">
                    <div className ="bTitle">
                        <span>การนัดหมายไม่สำเร็จ</span>
                        <p>
                            ขออภัยคุณมาช้าไป<br />ช่วงเวลานี้ถูกจองแล้ว<br />(40401)
                        </p>
                    </div>
                    <div className ="bBtn">
                        <button className ="btn btnCloseModal" onClick={onFail}>ทำการนัดหมายใหม่</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ModalFailMeeting
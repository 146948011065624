import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';

function ModalBookingSuccess({ 
  open = false, 
  onClose = () => console.log('onClose')
}) {
  return (
    <Dialog visible={open} style={{ width: '500px' }} onHide={() => onClose()} draggable={false} resizable={false} closable={false}>
      <div className ="bModal bCard" id="confirmModal" style={{ display: 'block' }}>
        <div className ="bInner">
          <div className ="bTitle">
            <img src="./assets/img/ci/register/ico-meeting.svg" />
            <span>
              นัดหมายสำเร็จ
              <br />
            </span>
            <p>คุณแม่รอรับการยืนยันการนัดหมาย<br />
            ได้ที่ LINE S-Mom Club นะคะ ขอบคุณค่ะ</p>
          </div>
          <div className ="bBtn">
            <button
              className ="btn btnCloseModal"
              // href="https://line.me/R/meeting/fcf3d6d6e6f240689dd1f0de7f75edc1"
              onClick={() => onClose()}
            >
              กลับสู่หน้าหลัก
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalBookingSuccess;

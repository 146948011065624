import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageStep from "components/PageStep";
import { useNavigate } from "react-router-dom";
import useSlotStore from "services/store/SlotModule";
import useBookingPage from "services/store/BookingPage";
import moment from "moment";
import CalendarUi from "components/CalendarUi";
import { SearchMonthlySlotList } from "services/Api/Module/SearchMonthlySlot";
import useLoadedStore from "services/store/LoadedPage";
const BookingTime = () => {
  const navigate = useNavigate();

  const { slots, rowCount } = useSlotStore((state) => state); // ดึง state
  const { getSlotList } = useSlotStore((state) => state); // ดึง action หรือ Fetch
  const { profile, step2, step3, setStep3, setStep1, setStep2 } = useBookingPage();

  const [consultDate, setConsultDate] = useState();
  const [usedDate, setUsedDate] = useState([]);

  const [yearMonth, setYearMonth] = useState(new Date());
  const [slot, setSlot] = useState("");
  const { isBooking } = useLoadedStore();


  const handleNext = async () => {
    storeForm();
    console.log("slot: ", slot);
    navigate("/conclustion");
  };

  const handlePrev = async () => {
    await storeForm();
    if (profile.ConsultSelected == "doctor") {
      navigate("/doctor-select");
    } else {
      navigate("/mom-detail");
    }
  };


  const handleChange = (event) => {
    setSlot(event.target.value);
  };

  const fetchSlotList = async () => {
    try {
      const resApi = await getSlotList(step2.doctor, profile.ConsultSelected, moment(consultDate).format("YYYY-MM-DD"),profile.LineMid)
    } catch (err) {
      console.log('Out error', err)
      alert("มีบางอย่างผิดพลาด")
    }
  };


  const setDefaultForm = async () => {
    setSlot(step3.slot);
    if (step3.consultDate) {
      setConsultDate(new Date(`${step3.consultDate}`));
    } else {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      setConsultDate(currentDate);
    }    
  };
  const storeForm = async () => {
    const payload = {
      consultDate: consultDate,
      slot: slot,
    };
    await setStep3(payload);
    console.log("payload >>", payload);
  };



  const fetchMonthlySlotList = async (payload = {}) => {
    try {
      const response = await SearchMonthlySlotList({ params: { ...payload } });
      const resData = response.data
      return resData;
    } catch (error) {
      console.error('Error adding user:', error);
      // return error;
      throw error;
    }
  }
  const postMonthlySlotList = async () => {
    const payload = {
      smDoctor: step2.doctor,
      consultSelected: profile.ConsultSelected,
      year: moment(yearMonth).format('YYYY'),
      month: moment(yearMonth).format('MM')
    }
    console.log('payload >>', payload)

    try {
      const res = await fetchMonthlySlotList(payload)
      if (res.resultCode == 20000) {
        const tmpData = res.resultData.map(item => item.consultDate)
        setUsedDate(tmpData)
      }
    } catch (e) {
      console.log('err >>', e)
    }

  }

  useEffect(() => {
    postMonthlySlotList();
  }, [yearMonth]);


  const resetForm = async () => {
    const payload = {
      age: "",
      consult: "",
      note: "",
    }
    const payload2 = {
      doctor: 0
    }
    const payload3 = {
      slot: "",
      consultDate: new Date(),
    }
    await setStep1(payload);
    await setStep2(payload2);
    await setStep3(payload3);
  }
  useEffect(() => {
    if (!isBooking) {
      resetForm();
      navigate("/");
    }
    setDefaultForm();
    // console.log("consult_date",consultDate)
  }, []);
  useEffect(() => {
    fetchSlotList();
  }, [consultDate]);
  return (
    <div>
      <Helmet>
        <title>S-Mom</title>
        <meta name="title" content="S-Mom" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageTimeSelect">
        <main>
          <header>
            <div className="tHead">
              <p>วัน และ เวลา นัดหมาย</p>
            </div>
          </header>
          <div className="wrapContent">
            <section id="pTimeSelect">
              {profile.ConsultSelected === 'doctor' ?
                (<PageStep step={3} />) : (<PageStep step={2} />)
              }
              <form>
                <div className="bContent">
                  <p>กรุณาระบุข้อมูลการนัดหมาย</p>
                  <div className="bBorder">
                    <div className="bBookingChoice">
                      <div className="daySelect">
                        <p>เลือกวันนัดหมาย</p>
                        <CalendarUi
                          value={consultDate}
                          usedDate={usedDate}
                          onChange={(e) => {
                            console.log('onChange OUT >>', e)
                            setSlot("");
                            setConsultDate(e);
                          }}

                          viewDate={yearMonth}
                          onMonthChange={(e) => {
                            console.log('onMonthChange OUT >>', e)
                            setYearMonth(e)
                          }}
                        />
                      </div>
                      <div className="timeSelect">
                        <p>เลือกเวลา</p>
                        <div className="bTime">
                          <div className="free">ว่าง</div>
                          <div className="noFree">ไม่ว่าง</div>
                          <div className="selectTime">เวลาที่เลือก</div>
                        </div>
                        {slots?.map((item) => {
                          const startTime = moment(item.consultTimeStart, "HH:mm");
                          const isNightTime = startTime.isBetween(moment("18:00", "HH:mm"), moment("23:59", "HH:mm"), undefined, '[]') ||
                            startTime.isBetween(moment("00:00", "HH:mm"), moment("05:59", "HH:mm"), undefined, '[]');
                          return (
                            <label key={item.id} className={`control control--radio ${!item.slotStatus ? 'disabled' : ''}`}>
                              {/* <!-- ใส่ className  disabled และ attribute disabled เพื่อปิดปุ่ม--> */}
                              <input type="radio" name="radio" disabled={!item.slotStatus} value={item.id} onChange={handleChange} checked={item.id == slot} />
                              <span className="tContent" data-time={isNightTime ? "night" : "day"}>
                                {moment(item.consultTimeStart, "HH:mm").format('HH:mm')} - {moment(item.consultTimeEnd, "HH:mm").format('HH:mm')} น.
                              </span>
                              {/* <!-- ใส่ data-time="" day หรือ "night" เพื่อเปลี่ยน icon หน้าเวลา--> */}
                              <div className="control__indicator"></div>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
          <section id="pActionBtn">
            <div className="bBtn">
              <button onClick={handlePrev} className="btn outline">
                ย้อนกลับ
              </button>
              <button
                onClick={handleNext}
                disabled={slot === ""}
                className="btn"
              >
                ถัดไป
              </button>
              {/* <!-- เมื่อเลือกประเภทแล้วให้เอา disabled ออก --> */}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default BookingTime;

import config from "util/config";

const CONFIG = config.config()
export async function GetBZBuserProfile( queryData = "" ) {
  const tokenAuth = CONFIG.TOKEN
  const bzbURL = CONFIG.BZB_URL
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {"Authorization": "token " + tokenAuth}
  };

  try {
    const response = await fetch(`${bzbURL}/user/profile?data=${queryData}`,requestOptions);
    return await response.json();
  } catch (error) {
    throw error
  };
}
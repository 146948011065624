import { SearchCounsultlist } from 'services/Api/Module/consult';
import { create } from 'zustand';
const useConsultStore = create((set) => ({
  consults: [],
  rowCount: 0,

  getConsultList: async (consultSelected) => {
    try {
      const response = await SearchCounsultlist({ params: { consultSelected } });
      const resData = response.data
      set({ consults: resData.resultData });
      set({ rowCount: resData.rowCount });
      return resData;
    } catch (error) {
      console.error('Error adding user:', error);
      // return error;
      throw error;
    }
  },


}));

export default useConsultStore;
import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

function AppNotLine() {
  // return "TEST";
  return (
    <Router>
      <Routes>

      </Routes>
    </Router>
  );
}

export default AppNotLine;

import { SearchDoctorList } from 'services/Api/Module/doctor';
import { create } from 'zustand';
const useDoctorStore = create((set) => ({
  doctors: [],
  rowCount: 0,

  getDoctorList: async (smConsultTopic , motherStage) => {
    try {
      const response = await SearchDoctorList({ params: { smConsultTopic, motherStage } });
      const resData = response.data
      set({ doctors: resData.resultData });
      set({ rowCount: resData.rowCount });
      return resData;
    } catch (error) {
      console.error('Error adding user:', error);
      // return error;
      throw error;
    }
  },


}));

export default useDoctorStore;
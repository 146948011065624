import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLiff } from "react-liff";

import Layouts from "layouts/Main";
import LoaderPage from "./components/loader/LoaderPage";

import AppRouter from "./appRouter"; // Router

import { setProfile } from "./features/ProfileSlice";
import { setRegister } from "./features/RegisterKlSlice";

// const devMode = true;
const devMode = false;

function App() {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
 

  // if (!devMode) {
  //   return <LoaderPage />;
  // }

  if (true) {
    return (
      <Layouts>
        <AppRouter/>
      </Layouts>
    );
  }
}

export default App;

import ModalProvider from "contexts/ModalProvider";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import useBookingPage from "services/store/BookingPage";
import useLoadedStore from "services/store/LoadedPage";
function Layouts({ children }) {
  const state = useBookingPage(state => state )
  const booking = useLoadedStore(state => state )
  
  console.log('state ALL >>', state)
  console.log('state ALL >>', booking)
  return <ModalProvider>
    
    {children}
  </ModalProvider>;
}

export default Layouts;

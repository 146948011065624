import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageStep from "components/PageStep";
import { useNavigate } from 'react-router-dom';
import useDoctorStore from "services/store/doctorModule";
import useBookingPage from "services/store/BookingPage";
import useLoadedStore from "services/store/LoadedPage";

const DoctorSelect = () => {

    const { doctors, rowCount } = useDoctorStore(state => state); // ดึง state
    const { getDoctorList } = useDoctorStore(state => state); // ดึง action หรือ Fetch
    const { profile, step1, step2, setStep2, setStep1, setStep3 } = useBookingPage();
    const { isBooking } = useLoadedStore();

    const navigate = useNavigate();

    const [doctor, setDoctor] = useState(0);

    const handleChange = (id) => {
        setDoctor(id);
    };

    const handleNext = async () => {
        storeForm();
        navigate('/booking-time');
    };
    const handlePrev = () => {
        storeForm();
        navigate('/mom-detail');
    };

    const fetchDoctorList = async () => {
        try {
            const resApi = await getDoctorList(step1.consult, profile.MotherStage)
            console.log('resApi >>', resApi)
        } catch (err) {
            console.log('Out error', err)
            alert("มีบางอย่างผิดพลาด")
        }
    };
    const storeForm = async () => {
        const payload = {
            doctor: doctor,
        }
        await setStep2(payload);
        console.log('payload >>', payload)
    }
    const setDefaultForm = async () => {
        setDoctor(step2.doctor)
    }
    const resetForm = async () => {
        const payload = {
            age: "",
            consult: "",
            note: "",
        }
        const payload2 = {
            doctor: 0
        }
        const payload3 =  {
            slot: "",
            consultDate:new Date(),
        }
        await setStep1(payload);
        await setStep2(payload2);
        await setStep3(payload3);
    }
    useEffect(() => {
        if(!isBooking){
            resetForm();
            navigate("/");
        }
        setDefaultForm();
        fetchDoctorList();
    }, []);
    return (
        <div>
            <Helmet>
                <title>S-Mom</title>
                <meta name="title" content="S-Mom" />
                <meta name="description" content="" />
                <meta name="keyword" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="" />
            </Helmet>
            <div id="pageDoctorSelect">
                <main>
                    <header>
                        <div className="tHead">
                            <p>
                                กรุณาเลือก<br />
                                คุณหมอ หรือ พยาบาล
                            </p>
                        </div>
                    </header>
                    <div className="wrapContent">
                        <section id="pDoctorSelect">
                            <PageStep step={2} />
                            <div className="bContent">
                                <p>ข้อมูลคุณหมอ และ พยาบาล</p>

                                <div className="bDoctorSelect">
                                    {doctors?.map((item, index) => (
                                        <div
                                            key={item?.id}
                                            className={`doctorDetail ${doctor === item?.id ? 'active' : ''}`}
                                            onClick={() => handleChange(item?.id)}
                                        >
                                            {/* <div className="doctorDetail active"> */}
                                            <div className="bDetail">
                                                <div className="tDetail">
                                                    <p>{item?.firstName} {item?.lastName}</p>
                                                    <div className="tDoctorDetail">
                                                        <p>{item?.position}</p>
                                                        <p>{item?.hospital}</p>
                                                    </div>
                                                </div>
                                                <img src={item?.doctorImg} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                    <section id="pActionBtn">
                        <div className="bBtn">
                            <button className="btn outline" onClick={handlePrev}>ย้อนกลับ</button>
                            <button className="btn" disabled={doctor === 0} onClick={handleNext}>ถัดไป</button>
                            {/* <!-- เมื่อเลือกแล้วให้เอา disabled ออก --> */}
                        </div>
                    </section>
                </main>
            </div>

        </div>
    )
}

export default DoctorSelect
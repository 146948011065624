import React, { useEffect,useState } from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment";
// import useMonthlySlotStore from "services/store/SearchMonthlySlotModule";
// import useBookingPage from "services/store/BookingPage";


export default function CalendarUi({ 
  value = null,
  viewDate = null,
  placeholder = "DD/MM/YYYY",
  usedDate = [],
  onChange = (e) => console.log('onChange'),
  onMonthChange = (e) => console.log('onMonthChange')

}) {
  const dateTemplate = (date, _usedDate = []) => {
    // console.log('date >>', date)
    // console.log('date 2>>', moment(date).format('YYYY-MM-DD'))
    // console.log('_usedDate >>', _usedDate)

    const strDate = moment(date).format('YYYY-MM-DD')
    if (_usedDate.includes(strDate)) {
      return (
        <strong>{date.day}</strong>
      );
    }

    return date.day;
  };

  function getAllDatesInMonth(monthString) {
    const dates = [];
    const startDate = moment(monthString, 'YYYY-MM');
    const endDate = startDate.clone().endOf('month');
  
    for (let date = startDate.clone(); date.isBefore(endDate) || date.isSame(endDate); date.add(1, 'day')) {
      dates.push(date.clone().format('YYYY-MM-DD'));
    }
  
    return dates;
  }

  const disabledDates = (yearMonth, _usedDate = []) => {
    const yearMonthStr = moment(yearMonth).format('YYYY-MM')
    const daysMonth = getAllDatesInMonth(yearMonthStr) // สร้างวันทั้งหมดของเดือนออกมา
    const disableDates = daysMonth.filter(item => !_usedDate.includes(item)) // เอาวันที่เลือกได้ออก
    const disableParseDates = disableDates.map(item => moment(item, 'YYYY-MM-DD').toDate()); // แปลงเป็น new Date()
    return disableParseDates
  }

  
  return (
    <div id="calendarApi">
      <Calendar
        dateFormat="dd/mm/yy"
        disabledDates={disabledDates(viewDate, usedDate)}
        touchUI
        placeholder={placeholder}
        showIcon
        icon={iconCalendar}
        dateTemplate={(date) => dateTemplate(date, usedDate)}

        // value ปฏิทิน
        value={value}
        onChange={(e) => {
          // console.log('onChange e IN >>', e)
          onChange(e.value)
        }}

        // popup value => yyyy-mm
        viewDate={viewDate}
        onViewDateChange={(e) => {
          // console.log('onViewDateChange e IN >>', e)
          onMonthChange(e.value)
        }}
      />
    </div>
  );
}


const iconCalendar = (options) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9167 1.07692H9.75V0H8.66667V1.07692H4.33333V0H3.25V1.07692H1.08333C0.4875 1.07692 0 1.56154 0 2.15385V12.9231C0 13.5154 0.4875 14 1.08333 14H11.9167C12.5125 14 13 13.5154 13 12.9231V2.15385C13 1.56154 12.5125 1.07692 11.9167 1.07692ZM11.9167 12.9231H1.08333V5.38462H11.9167V12.9231ZM11.9167 4.30769H1.08333V2.15385H3.25V3.23077H4.33333V2.15385H8.66667V3.23077H9.75V2.15385H11.9167V4.30769Z"
        fill="#A02018"
      />
    </svg>
  );
};


import moment from 'moment';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useBookingPage = create(
    persist(
        (set) => ({
            profile: {
                
            },
            step0:{

            },
            step1: {
                age: "",
                consult: "",
                note: "",
            },
            step2: {
                doctor: 0,
            },
            step3: {
                slot: "",
                consultDate:moment().add(1, 'days').toDate(),
            },
            setProfile: async (payload) =>{
                set((state) => ({
                    profile: {...state.profile, ...payload}
                }))
            },
            setStep1: async (payload) => {
                set((state) => ({
                    step1: { ...state.step1, ...payload }
                }))
            },
            setStep2: async (payload) => {
                set((state) => ({
                    step2: { ...state.step2, ...payload }
                }))
            },
            setStep3: async (payload) => {
                set((state) => ({
                    step3: { ...state.step3, ...payload }
                }))
            },
            setStep0: async (payload) => {
                set((state) => ({
                    step0: { ...state.step0, ...payload }
                }))
            },
           


        }), {
        name: 'bookingStore'
    }
    )
);

export default useBookingPage;
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PageStep from "components/PageStep";
import { useNavigate } from 'react-router-dom';
import useConsultStore from "services/store/consultModule";
import useBookingPage from "services/store/BookingPage";
import useLoadedStore from "services/store/LoadedPage";

const MomDetail = () => {
    const { consults, rowCount } = useConsultStore(state => state); // ดึง state
    const { getConsultList } = useConsultStore(state => state); // ดึง action หรือ Fetch
    const { profile, step1, setStep1,setStep2,setStep3 } = useBookingPage();
    const { isBooking } = useLoadedStore();

    // local state 
    const [consult, setConsult] = useState("");
    const [age, setAge] = useState("");
    const [note, setNote] = useState("");

    const navigate = useNavigate();

    const handleNext = async () => {
        await storeForm();
        if (profile.ConsultSelected == 'doctor') {
            navigate('/doctor-select');
        } else {
            navigate('/booking-time')
        }
    };
    const handlePrev = () => {
        storeForm();
        navigate('/register-policy');
    };

    const handleChange = (event) => {
        setConsult(event.target.value);
    };
    const handleAgeChange = (event) => {
        const value = event.target.value;
        // Use a regular expression to allow only numbers and limit to 2 digits
        if (value.match(/^\d{0,2}$/)) {
            setAge(value); // Assuming setAge is your state update function
        }

    };
    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        // Limit the input to 255 characters
        if (value.length <= 255) {
            setNote(value); // Assuming setNote is your state update function
        }
    }


    const fetchConsultList = async () => {
        try {
            const resApi = await getConsultList(profile.ConsultSelected)
            console.log('resApi >>', resApi)
        } catch (err) {
            console.log('Out error', err)
            alert("มีบางอย่างผิดพลาด")
        }
    };
    const storeForm = async () => {
        const payload = {
            age: age,
            consult: consult,
            note: note,
        }
        await setStep1(payload);
        console.log('payload >>', payload)
    }
    const setDefaultForm = async () => {
        setConsult(step1.consult);
        setNote(step1.note);
        if (profile.MotherStage == "Pregnancy") {
            setAge(profile.PregnancyAge);
        } else {
            setAge(profile.BabyAge)
        }
    }
    const resetForm = async () => {
        const payload = {
            age: "",
            consult: "",
            note: "",
        }
        const payload2 = {
            doctor: 0
        }
        const payload3 =  {
            slot: "",
            consultDate:new Date(),
        }
        await setStep1(payload);
        await setStep2(payload2);
        await setStep3(payload3);
    }
    useEffect(() => {
        if(!isBooking){
            resetForm();
            navigate("/");
        }
        setDefaultForm();
        fetchConsultList();
    }, []);

    return (
        <div>
            <Helmet>
                <title>S-Mom</title>
                <meta name="title" content="S-Mom" />
                <meta name="description" content="" />
                <meta name="keyword" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="" />
            </Helmet>
            <div id="pageMomDetail">
                <main>
                    <header>
                        <div className="tHead">
                            <p>
                                กรุณาระบุข้อมูล<br />
                                ที่ต้องการรับคำปรึกษา
                            </p>
                        </div>
                    </header>
                    <div className="wrapContent">
                        <section id="pMomdetail">
                            <PageStep step={1} />
                            <div className="bContent">
                                <p>ข้อมูลคุณแม่ หรือ ลูกน้อย</p>
                                <div className="bForm">
                                    <div className="tContent">
                                        <div className="control-group validate">
                                            <div className="tTitle  error">
                                                {profile.MotherStage == "Pregnancy" ? 'อายุครรภ์(สัปดาห์)' : 'อายุบุตร(เดือน)'}
                                            </div>
                                            {/* <!-- <div className ="tTitle  error">อายุบุตร</div> --> */}
                                            <input placeholder="ตัวอย่าง 2 สัปดาห์" type="text" name="text" value={age} onChange={handleAgeChange} />
                                            <label htmlFor="" className="tError">Text Error Input</label>
                                        </div>
                                        <div className="control-group validate">
                                            <div className="tTitle-select error">เรื่องที่ต้องการรับคำปรึกษา</div>
                                            <div className="select">
                                                <select value={consult} onChange={handleChange}>
                                                    <option value="">เลือกเรื่องที่ต้องการปรึกษา</option>
                                                    {consults?.map((item, index) => (
                                                        <option key={index} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <label htmlFor="" className="tError">Text Error Input</label>
                                        </div>
                                        <div className="control-group validate">
                                            <div className="tTitle-textarea error">โปรดระบุรายละเอียด</div>
                                            <textarea placeholder="กรอกรายละเอียดเพิ่มเติม" type="text" name="text" value={note} onChange={handleDescriptionChange}></textarea>
                                            <label htmlFor="" className="tError">Text Error Input</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section id="pActionBtn">
                        <div className="bBtn">
                            <button onClick={handlePrev} className="btn outline">ย้อนกลับ</button>
                            <button onClick={handleNext} className="btn" disabled={age === "" || consult === "" || note === ""}>ถัดไป</button>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default MomDetail
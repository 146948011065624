import { SearchSlotList } from 'services/Api/Module/Slot';
import { create } from 'zustand';
const useSlotStore = create((set) => ({
  slots: [],
  rowCount: 0,

  getSlotList: async (smDoctor,consultSelected,consultDate,lineMid) => {
    try {
      const response = await SearchSlotList({ params: { smDoctor,consultSelected,consultDate,lineMid } });
      const resData = response.data
      set({ slots: resData.resultData });
      set({ rowCount: resData.rowCount });
      return resData;
    } catch (error) {
      console.error('Error adding user:', error);
      // return error;
      throw error;
    }
  },


}));

export default useSlotStore;
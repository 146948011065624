import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PageStep from "components/PageStep";
import { useNavigate } from 'react-router-dom';
import useBookingPage from "services/store/BookingPage";
import useConsultStore from "services/store/consultModule";
import useDoctorStore from "services/store/doctorModule";
import useSlotStore from "services/store/SlotModule";
import { CreateBooking } from "services/Api/Module/Meeting";
import moment from 'moment';
import ModalBookingSuccess from "components/modal/ModalBookingSuccess";
import ModalCancelBooking from "components/modal/ModalCancelBooking";
import ModalFailMeeting from "components/modal/ModalFailMeeting";
import ModalNetwork from "components/modal/ModalNetwork";
import useLoadedStore from "services/store/LoadedPage";
import ModalFail from "components/modal/ModalFail";
import config from "util/config";
import ModalNotEnoughPoint from "components/modal/ModalNotEnoughPoint";

const CONFIG = config.config()
const Conclustion = () => {
    const liffId = CONFIG.LIFF_ID;
    const { consults, getConsultList } = useConsultStore(state => state); // ดึง state
    const { profile, step0, step1, step2, step3, setStep1, setStep2, setStep3 } = useBookingPage();
    const { doctors, getDoctorList } = useDoctorStore(state => state); // ดึง state
    const { slots, getSlotList } = useSlotStore(state => state); // ดึง state
    const [isOpenSuccess, setIsOpenSuccess] = useState(false); // ดึง state
    const [isOpenFail, setIsOpenFail] = useState(false); // ดึง state
    const [isOpenNetwork, setIsOpenNetwork] = useState(false); // ดึง state
    const [isOpenFailMeeting, setIsOpenFailMeeting] = useState(false); // ดึง state
    const [isCancelBooking, setIsCancelBooking] = useState(false); // ดึง state
    const [isOpenNoPoint, setIsOpenNoPoint] = useState(false); // ดึง state
    const [statusCode, setStatusCode] = useState(false); // ดึง state
    const [ disbutton , setDisButton ] = useState(false);
    const { isBooking } = useLoadedStore();

    const navigate = useNavigate();
    const handleNext = async () => {
        setDisButton(true);
        const payload = {
            userId: profile.UserId,
            consultSelected: profile.ConsultSelected,
            lineMid: profile.LineMid,
            smConsultTopic: step1.consult,
            smSlot: step3.slot,
            note: step1.note,
            usedpoint: profile.Point,
            isConsent: step0.isConsent,
            motherStage: profile.MotherStage,
            babyAge: profile.BabyAge,
            pregnancyAge: profile.PregnancyAge,
            campaignId: profile.CampaignId,
            smDoctor: step2.doctor,
            childId: profile.ChildId
        }
        console.log(profile.pregnancyAge)
        await postMeeting(payload);
    };
    const handlePrev = () => {
        setIsCancelBooking(true);
    };
    const handleLink = () => {
        window.location.href = `https://liff.line.me/${liffId}`;
    };
    const handleFail = () => {
        navigate('/mom-detail');
    }
    const fetchConsultList = async () => {
        try {
            const resApi = await getConsultList(profile.ConsultSelected)
        } catch (err) {
            console.log('Out error', err)
            alert("มีบางอย่างผิดพลาด")
        }
    };
    const fetchDoctorList = async () => {
        try {
            const resApi = await getDoctorList(step1.consult, profile.MotherStage)
        } catch (err) {
            console.log('Out error', err)
            alert("มีบางอย่างผิดพลาด")
        }
    };
    const fetchSlotList = async () => {
        try {
            const resApi = await getSlotList(step2.doctor, profile.ConsultSelected, moment(step3.consultDate).format("YYYY-MM-DD"))
            console.log("fetchSlotlist", resApi)
        } catch (err) {
            console.log('Out error', err)
            alert("มีบางอย่างผิดพลาด")
        }
    };
    const postMeeting = async (payload = {}) => {
        try {
            const resApi = await CreateBooking({ data: payload })
            if (resApi?.data?.resultCode == 20000) {
                setIsOpenSuccess(true);
            } else if (resApi?.data?.resultCode == 40401) {
                setIsOpenFailMeeting(true);
            } else if (resApi?.data?.resultCode == 40501){
                setStatusCode(resApi?.data?.resultCode | "");
                setIsOpenNoPoint(true);
            } else {
                setStatusCode(resApi?.data?.resultCode | "");
                setIsOpenFail(true);
            }
        } catch (err) {
            console.log('Out error', err)
            alert("มีบางอย่างผิดพลาด")
        }
    };
    const resetForm = async () => {
        const payload = {
            age: "",
            consult: "",
            note: "",
        }
        const payload2 = {
            doctor: 0
        }
        const payload3 = {
            slot: "",
            consultDate: new Date(),
        }
        await setStep1(payload);
        await setStep2(payload2);
        await setStep3(payload3);
    }
    useEffect(() => {
        if (!isBooking) {
            resetForm();
            navigate("/");
        }
        fetchConsultList();
        fetchDoctorList();
        fetchSlotList();
    }, []);
    const TimeDiff = (startDateTime, endDateTime) => {
        const startTime = moment(startDateTime, "HH:mm");
        const endTime = moment(endDateTime, "HH:mm");
        const difference = endTime.diff(startTime, "minutes");
        return difference
    };

    const selectDoctor = doctors.find(item => item.id == step2.doctor)
    const selectConsult = consults.find(item => item.id == step1.consult)
    const selectSlot = slots.find(item => item.id == step3.slot)
    console.log("slots", slots)
    return (
        <div>
            <Helmet>
                <title>S-Mom</title>
                <meta name="title" content="S-Mom" />
                <meta name="description" content="" />
                <meta name="keyword" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="" />
            </Helmet>
            <div id="pageConclustion">
                <main>
                    <header>
                        <div className="tHead">
                            <p>การนัดหมายของคุณแม่</p>
                        </div>
                    </header>
                    <div className="wrapContent">
                        <section id="pConclusion">
                            {profile.ConsultSelected === 'doctor' ?
                                (<PageStep step={4} />) : (<PageStep step={3} />)
                            }
                            <div className="bContent">
                                <h2>สรุปข้อมูลการนัดหมาย</h2>
                                <div className="bDetail">
                                    <p className="topic">รายละเอียดการนัดหมาย</p>
                                    <div className="tDetail">
                                        <div className="meetingDetail">
                                            <p>วันที่</p>
                                            <p className="tBold">{moment(step3.consultDate).format("YYYY-MM-DD")}</p>
                                        </div>
                                        <div className="meetingDetail">
                                            <p>เวลา</p>
                                            <p className="tBold">
                                                {moment(selectSlot?.consultTimeStart, "HH:mm").format("HH:mm - ")}
                                                {moment(selectSlot?.consultTimeEnd, "HH:mm").format("HH:mm น.")}
                                            </p>
                                        </div>
                                        <div className="meetingDetail">
                                            <p>สถานะ</p>
                                            <p className="tBold">{profile.MotherStage == "Pregnancy" ? "คุณแม่ตั้งครรภ์" : "คุณแม่มีบุตร"}</p>
                                        </div>
                                        <div className="meetingDetail">
                                            <p>หัวข้อที่ปรึกษา</p>
                                            <p className="tBold">{selectConsult?.name}</p>
                                        </div>
                                        <div className="meetingDetail">
                                            <p>รายละเอียด</p>
                                            <p className="tBold">{step1.note}</p>
                                        </div>
                                        {profile.ConsultSelected == "doctor" ? (
                                            <div className="meetingDetail">
                                                <p>ผู้เชี่ยวชาญ</p>
                                                <p className="tBold">{selectDoctor?.firstName} {selectDoctor?.lastName}</p>
                                            </div>
                                        ) : null}
                                        <div className="meetingDetail">
                                            <p>ระยะเวลา</p>
                                            <p className="tBold">{TimeDiff(selectSlot?.consultTimeStart, selectSlot?.consultTimeEnd)} นาที</p>
                                        </div>
                                    </div>
                                    <div className="bNote">
                                        <p>หมายเหตุ</p>
                                        <div className="tNote">
                                            <p>กรุณาเข้านัดปรึกษาตามวัน / เวลาที่ระบุ<br /> หากเข้ารับคำปรึกษาล่าช้าเกินกว่า 10 นาที <br />การนัดหมายในครั้งนั้นจะถูกยกเลิกโดยอัตโนมัติ</p>
                                            <br />
                                            <p className="tRed">
                                                !! ขอสงวนสิทธิ์ในการไม่บันทึกเสียง / ภาพ หรือ วิดีโอ ขณะรับคำปรึกษาในทุกกรณี
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section id="pActionBtn">
                                <div className="bBtn">
                                    <button className="btn outline" onClick={handlePrev}>ยกเลิก</button>
                                    <button className="btn" onClick={handleNext} id="confirm" disabled={disbutton}>
                                        ยืนยันการนัดหมาย
                                    </button>
                                    {/* <!-- เมื่อเลือกประเภทแล้วให้เอา disabled ออก --> */}
                                </div>
                            </section>
                        </section>
                    </div>
                </main>
            </div>
            <ModalBookingSuccess open={isOpenSuccess} onClose={handleLink} />
            <ModalFailMeeting open={isOpenFailMeeting} onFail={handleFail} onClose={() => setIsOpenFailMeeting(false)} />
            <ModalFail open={isOpenFail} onClose={() => setIsOpenFail(false)} statuscode={statusCode}/>
            <ModalNetwork open={isOpenNetwork} onClose={handleLink} />
            <ModalNotEnoughPoint open={isOpenNoPoint} onClose={() => setIsOpenNoPoint(false)} statuscode={statusCode}/>
            <ModalCancelBooking open={isCancelBooking} onClose={() => setIsCancelBooking(false)} onCancel={() => navigate("/mom-detail")} />
        </div>
    )
}

export default Conclustion
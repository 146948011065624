import React, {  useEffect, useState } from "react";
import ModalFail from "components/modal/ModalFail";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
const HandlePage = () => {
    const [searchParams] = useSearchParams();
    const [isOpenFail, setIsOpenFail] = useState(true);
    const resultCode =  searchParams.get('resultCode');
    const handleLink = () => {
        window.location.href = 'https://liff.line.me/1653771770-r4MNMYgk';
    };
    return (
        <div>
            <Helmet>
                <title>S-Mom</title>
                <meta name="title" content="S-Mom" />
                <meta name="description" content="" />
                <meta name="keyword" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="" />
            </Helmet>
            <div id="pagePolicy">
                <main>
                    <header>
                        <div className="tHead">
                            <p>ข้อมูลความเป็นส่วนตัว</p>
                        </div>
                    </header>
                    <div className="wrapContent">
                        <section id="pPolicy">
                            <div className="bContent">
                                <p>ยินยอมข้อมูลความเป็นส่วนตัว</p>
                                <div className="container">
                                    <div className="tContent">
                                        <p>
                                            S-MomClub สวัสดีค่ะ การปรึกษาแพทย์และทีมพยาบาลออนไลน์
                                            เนสท์เล่จำเป็นต้องขออนุญาตเก็บข้อมูลการสนทนาและข้อมูลส่วนบุคคล
                                            ซึ่งรวมถึงข้อมูลที่มีความอ่อนไหว เช่น ข้อมูลสุขภาพของคุณ
                                            ลูกค้าตามกฎหมาย
                                        </p>
                                        <br />
                                        <p>
                                            สามารถดูประกาศความเป็นส่วนตัวของเนสท์เล่ ได้ที่<br />
                                            <a href="">https://www.s-momclub.com/privacy-policy </a>
                                        </p>
                                        <br />
                                        <p>
                                            หากยินยอมให้มีการเก็บข้อมูล กรุณากดปุ่ม "ยินยอม" หรือ
                                            "ไม่ยินยอม"เพื่อที่ทางบริษัทจะได้ดำเนินการต่อได้นะคะ
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section id="pActionBtn">
                        <div className="bBtn">
                            <button className="btn outline">ไม่ยินยอม</button>
                            <button className="btn">ยินยอม</button>
                            {/* <!-- เมื่อเลือกประเภทแล้วให้เอา disabled ออก --> */}
                        </div>
                    </section>
                </main>
            </div>
            <ModalFail open={isOpenFail} onClose={handleLink} statuscode={resultCode} />
        </div>
    )
}

export default HandlePage
import React from "react";
import { Dialog } from 'primereact/dialog';

const ModalPDPA = ({
    open = false,
    onClose = () => console.log('onClose'),
    onYes = () => console.log('yes'),
    onNo = () => console.log('No')
}
) => {
    return (
        <Dialog visible={open} style={{ width: '500px' }} onHide={() => onClose()} draggable={false} resizable={false} closable={false}>
            <div className="bModal bCard" id="confirm" style={{ display:'block'}}>
                <div className="bInner">
                    <div className="bTitle">
                        <span>กรณีที่คุณแม่ไม่ยินยอม</span>
                        <div className="bPdpa">
                            <p className="">
                                หากไม่ยินยอม
                                เนสท์เล่จะไม่เก็บและประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของลูกค้า
                                ยกเว้นข้อมูลส่วนบุคคลบางส่วนที่ทางเนสท์เล่จำเป็นต้องเก็บหรือประมวลผลตามฐานทางกฎหมายที่มีเท่านั้น
                            </p>
                            <br />
                            <p>
                                ตามประกาศความเป็นส่วนตัวของเนสท์เล่
                                (https://www.s-momclub.com/privacy-policy)
                            </p>
                            <br />
                            <p>กรุณากดปุ่ม "ยินยอม" หรือ "ไม่ยินยอม"</p>
                        </div>
                    </div>
                    <div className="bBtn">
                        <button className="btn btnCloseModal"  onClick={onYes}>ยินยอม</button>
                        <button className="btn outline" onClick={onNo}>ไม่ยินยอม</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ModalPDPA